body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;


  overflow-x:hidden;
  overflow-y:hidden;



}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
}




a {
  text-decoration:none;
}







/* Arial Font */


@font-face {
  font-family: 'Arial-Regular';
  src: local('Arial-Regular'), url(./fonts/Arial/Arial-Regular.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
    font-family: 'Arial-Black';
    src: local('Arial-Black'), url(./fonts/Arial/Arial-Black.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}

@font-face {
    font-family: 'Arial-Italic';
    src: local('Arial-Italic'), url(./fonts/Arial/Arial-Italic.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                                'embedded-opentype', and 'svg' */
}
    
@font-face {
  font-family: 'Arial-Bold';
  src: local('Arial-Bold'), url(./fonts/Arial/Arial-Bold.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}



@font-face {
  font-family: 'Arial-Narrow';
  src: local('Arial-Narrow'), url(./fonts/Arial/Arial-Narrow.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
@font-face {
    font-family: 'Arial-Narrow-Italic';
    src: local('Arial-Narrow-Italic'), url(./fonts/Arial/Arial-Narrow-Italic.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                                'embedded-opentype', and 'svg' */
}
@font-face {
    font-family: 'Arial-Narrow-Bold';
    src: local('Arial-Narrow-Bold'), url(./fonts/Arial/Arial-Narrow-Bold.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}
@font-face {
font-family: 'Arial-Narrow-Bold-Italic';
src: local('Arial-Narrow-Bold-Italic'), url(./fonts/Arial/Arial-Narrow-Bold-Italic.ttf) format('truetype');
/* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}












/* Formula 1 Font */


@font-face {
  font-family: 'Formula1-Regular';
  src: local('Formula1-Regular'), url(./fonts/Formula1/Formula1-Regular_web_0.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}


@font-face {
  font-family: 'Formula1-Bold';
  src: local('Formula1-Bold'), url(./fonts/Formula1/Formula1-Bold_web_0.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}


@font-face {
  font-family: 'Formula1-Wide';
  src: local('Formula1-Wide'), url(./fonts/Formula1/Formula1-Wide_web_0.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}







/* Roboto Font */


@font-face {
  font-family: 'Roboto-Regular';
  src: local('Roboto-Regular'), url(./fonts/Roboto/Roboto-Regular.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Roboto-Bold';
  src: local('Formula1-Bold'), url(./fonts/Roboto/Roboto-Bold.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Roboto-Thin';
  src: local('Formula1-Thin'), url(./fonts/Roboto/Roboto-Thin.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}


@font-face {
  font-family: 'Roboto-Light';
  src: local('Roboto-Light'), url(./fonts/Roboto/Roboto-Regular.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Roboto-Medium';
  src: local('Roboto-Medium'), url(./fonts/Roboto/Roboto-Regular.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}









/* Lato Font */


@font-face {
  font-family: 'Lato-Regular';
  src: local('Lato-Regular'), url(./fonts/Lato/Lato-Regular.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Lato-Bold';
  src: local('Lato-Bold'), url(./fonts/Lato/Lato-Bold.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'Lato-Thin';
  src: local('Lato-Thin'), url(./fonts/Lato/Lato-Thin.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}





